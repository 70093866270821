<template>
    <div class="modal-mask">
        <div class="modal-container">
            <div class="seller-header">
                {{ title }}
                <span class="material-icons seller-header-close" v-on:click="close">cancel</span>
            </div>
            <div class="tab-selector-container">
                <button @click="tab = 'clients'" :class="{'tab-button-activated': tab == 'clients'}" class="tab-button">Clientes</button>
                <button @click="tab = 'participation'"  :class="{'tab-button-activated': tab == 'participation'}" class="tab-button">Histórico</button>
            </div>
            <div style="height: 80vh; overflow-y: auto;">
                <SellerClients v-if="tab == 'clients'" :setSearch="setSearch" :sellerId=" seller_id " />
                <SellerParticipationModal v-else :propSearchText="searchText" :sellerId="seller_id"/>
            </div>
        </div>
    </div>
</template>

<script>
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import SellerClients from "./_seller.clients.vue"
import SellerParticipationModal from "@/modals/providers/sellers/sellers-participation.modal";

export default {
    mixins: [loaderMixin],
    props: ["provider", "seller_id", "to_clients", "sellerName"],
    components: {
        SellerClients,
        SellerParticipationModal
    },
    data() {
        return {
            title: 'Vendedores',
            tab: 'clients',
            searchText: ''
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        setSearch(value){
            this.searchText = value
            this.tab = 'participation'
        }
    },
    async created() {
        this.VIEW = 'SELLER_CLIENTS'
        this.title = 'Vendedor(a): ' + this.sellerName 
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/commons/_variables';


.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-container {
    width: 110vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    margin-top: 15vh;
}

.seller-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px 10px 0 0;
    padding: 1vh 1vw;
    font-size: 2em;
    color: white;
}

.seller-header-close {
    cursor: pointer;
    font-size: 1.2em;
}

.tab-button{
    border: 1px solid var(--primary-color);
    background: white;
    font-size: 1.3em;
    color: var(--primary-color);
    border-radius: 8px;
    width: 10vw;
    text-align: center;
    padding: 1vh 0;
}
.tab-button-activated{
    color: white;
    background-color: var(--primary-color);
}

.tab-selector-container{
    display: flex;
    margin: 2vh 0;
    gap: 3vw;
    padding: 2em;
}

@media only screen and (max-width: 1000px) {
    .modal-mask {
        font-size: 1.5vw;
    }
}

@media only screen and (max-width: 600px) {
    .modal-mask {
        font-size: 3.5vw;
    }

    .desktop {
        display: none;
    }

    .modal-container {
        width: 115vw;
    }
}</style>
