import http from "../http";
import Service from "../../Service";

/**
 * @type FilterService
 */
export default class FilterService extends Service {

    providers() {
        return http.get(`filters/providers`, {
			headers: this.getHeaders()
		});
    }
}
