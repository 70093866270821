<template>
    <div :style="getWidth()">
      <div class="input-title">{{title}}</div>
      <div class="input-containear" v-on:click="openSelect()">
        <input type="text" v-model="productSearch" class="input-value" :placeholder="placeholder ? placeholder : propPlaceholder">
        <span v-if="!isSelectOpen" class="material-icons input-icon">search</span>
        <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect()">close</span>
      </div>
      <div v-if="isSelectOpen">
        <div class="select-body" :style="getSelectWidth()">
          <div v-for="(x, idx) in list" :key="idx">
            <div v-if="showProduct(x.text)" v-on:click="callAction(x)" class="select-body-line">
              <div class="select-body-line-text">{{x.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isSelectOpen" class="iput-close-body" v-on:click="closeSelect()"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StandardInputListFiltered',
    props: ['title', 'list', 'action', 'propPlaceholder', 'selectWidth', 'width', 'actionTwo', 'value'],
    data () {
      return {
        isSelectOpen: false,
        productSearch: '',
        placeholder: ""
      }
    },
    methods: {
      getWidth(){return 'width: ' + this.width},
      getSelectWidth(){
        if (this.selectWidth) return 'width: ' + this.selectWidth
        else return 'min-width: 20vw'
      },
      openSelect () { this.isSelectOpen = true },
      closeSelect () { this.isSelectOpen = false },
      showProduct (text) {
        if (this.productSearch === '') return true
        if (text.toLowerCase().includes(this.productSearch.toLowerCase())) return true
        return false
      },
      callAction(value) {
        this.action(value.value)
        this.productSearch = ''
        this.placeholder = value.text
        this.isSelectOpen = false
      }
    },
    watch: {
        productSearch(value) {
           this.actionTwo(value)
        },
        value (newValue) {
          if (!newValue) {
            this.productSearch = ''
            this.placeholder = ''
          }
        }
    }
  }
  </script>
  
  <style scoped>
  .input-title{
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.15px;
    color: #898989;
  }
  .input-containear{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.5vh 0.7vw;
    height: 6vh;
    display: flex;
  }
  .input-value{
    border: none;
    flex: 1;
    font-weight: 400;
    font-size: 1.2em;
    letter-spacing: 0.15px;
    color: #B3B3B3;
    width: 90%;
  }
  .input-value:focus{
    border: none;
    outline: none;
  }
  .input-icon{
    color: #B3B3B3;
    cursor: pointer;
    font-size: 1.8em;
  }
  .input-select-value{
    border: none;
    font-weight: 400;
    font-size: 1.3em;
    letter-spacing: 0.15px;
    color: #B3B3B3;
    flex: 1;
  }
  .input-select-value:focus{
    border: none;
    outline: none;
  }
  .select-body{
    position: absolute;
    z-index: 3;
    background-color: white;
    overflow-y: scroll;
    max-height: 300px;
  }
  .select-body-line{
    display: flex;
    align-items: center;
    padding: 10px 2px;
    cursor: pointer;
    flex: 1;
  }
  .select-body-line:hover{ background-color: rgba(255, 247, 247, 0.75); }
  .select-body-line-text{
    font-weight: 300;
    font-size: 15px;
    color: #505050;
    margin-left: 5px;
  }
  .iput-close-body{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  </style>