<template>
    <div >
        <div style="display: flex; margin: 0 2em; align-items: flex-end; gap: 5vw;">
            <StandardInput title="Buscar vendedor" type="text" :action="v => searchText = v" />
            <span v-if="searchText" class="search-text">{{ searchText }}</span>
        </div>
        <cc-loader style="margin-top: 20px;" v-if="loading"/>
        <no-data-available v-if="!loading && requests.length == 0" :text="'Sem participações encontradas'" />
        <div v-if="!current_request && !loading && requests.length > 0" class="participation-table-container">
            <div class="page-table-content">
                <div class="page-table-header">
                    <div class="page-table-header-text id-width">ID</div>
                    <div class="page-table-header-text client-width">Cliente</div>
                    <div class="page-table-header-text products-width">Produtos</div>
                    <div class="page-table-header-text competitors-width">Concorrentes</div>
                    <div class="page-table-header-text status-width">Status</div>
                    <div class="page-table-header-text created-at-width">Criado em</div>
                </div>
                <div v-for="(request, idx) in showRequest" :key="idx" :class="getSpecialBackground(idx)">
                    <div class="page-table-row">
                        <div class="page-table-desc-column id-width">{{ request.id || '-' }}</div>
                        <div class="page-table-desc-column client-width">{{ request.name || '-' }}</div>
                        <div class="page-table-desc-column products-width">
                            <span class="prod-count" @click="current_request = request; isOffersPartialOpen = true">
                                {{ request.products_count }}
                            </span>
                        </div>
                        <div class="page-table-desc-column competitors-width">{{ request.sellers_count }}</div>
                        <div class="page-table-desc-column status-width">
                            <cc-status :status="request.request_seller_status"></cc-status>
                        </div>
                        <div class="page-table-desc-column created-at-width">{{ request.created_at | formatDate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <SellerOffers v-if="current_request && isOffersPartialOpen" @close="isOffersPartialOpen = false; current_request = null;" :request_id="current_request.id" :seller_id="sellerId" />
        </div>
    </div>
</template>

<script>
import RequestService from "@/services/v3/request.service";
import SellerService from "@/services/v3/providers/seller.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import SellerOffers from "@/modals/providers/sellers/_offers.partial"
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'

export default {
    mixins: [loaderMixin],
    props: ["sellerId", "propSearchText"],
    components: {
        SellerOffers,
        StandardInput
    },
    data() {
        return {
            loading: false,
            service: new RequestService(),
            seller_svc: new SellerService(),
            requests: [],
            current_seller: null,
            current_request: null,
            searchText: '',
            filters: {},
            statuses: [
                { id: 'ALL', name: 'Todos' },
                { id: 'NOT_TYPED', name: 'Não Digitados' },
                { id: 'TYPED', name: 'Digitados' },
                { id: 'ORDERED', name: 'Pedido Gerado' },
                { id: 'NOT_ORDERED', name: 'Sem Pedido' },
                { id: 'NOT_OFFERED', name: 'Não oferta' },
            ],
            isOffersPartialOpen: false,
        }
    },
    watch: {
        'filters.status': function () {
            this.providers.forEach(p => p.visible = (
                this.filters.status == null ||
                this.filters.status.id == "ALL" ||
                this.filters.status.id == p.status))
        }
    },
    computed: {
        showRequest() {
            if (this.searchText) return this.requests.filter(e => e.name.toUpperCase().includes(this.searchText.toUpperCase()))
            return this.requests
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        load_requests() {
            this.loading = true;
            let params = { seller_id: this.sellerId, with_pagination: 0 }
            return this.service.list(params).then((response) => {
                this.loading = false;
                this.requests = response.data
            }).catch(err => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    },
    created() {
        this.load_requests()
        this.searchText = this.propSearchText
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 80vw;
    height: 80vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.6em;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.participation-table-container {
    margin: 2em;
    overflow: auto;
}
.search-text{
    color: var(--primary-color);
    padding: 1vh 2vw;
    border: var(--primary-color) 1px solid;
    border-radius: 8px;
}
.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}

.page-table-line-special {
    background: #F7F7F7;
}

.id-width {
    width: 10%;
}

.client-width {
    width: 26%;
}

.products-width {
    width: 16%;
}

.competitors-width {
    width: 14%;
}

.status-width {
    width: 14%;
}

.created-at-width {
    width: 16%;
}

.confirmed {
    color: greenyellow;
}

.prod-count {
    border: 1px solid #ff7110;
    padding: 4px;
    text-align: center;
    display: inline-block;
    color: var(--primary-color);
    font-size: 0.8rem;
    border-radius: 5px;
    min-width: 40px;

    &:hover {
        background-color: var(--primary-color);
        color: white;
        cursor: pointer;
    }
}</style>
