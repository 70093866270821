import http from "./http";
import Service from "../Service";

/**
 * @type ClientService
 */
export default class ClientService extends Service {

	update_client(client) {
		return http.put(`clients/${client.cli_id}`, client, {
			headers: this.getHeaders()
		});
	}

    list_client_stores() {
		return http.get("clients", {
			headers: this.getHeaders(),
            params: {
                page_size: 5000
            }
		});
    }

	addLead (lead) {
		return http.post('external/clients/club', lead, {})
	}

}
